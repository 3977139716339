import React, { useState } from "react";
import { Statecontext } from "./contexts";

function Appstate(props) {
  const [state, setState] = useState({
    currentcard: {},
  });
  return (
    <Statecontext.Provider value={{ ...state, setState: setState }}>
      {props.children}
    </Statecontext.Provider>
  );
}

export default Appstate;
