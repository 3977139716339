import { Fab } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import PropTypes from "prop-types";

function FloatingButton(props) {
  let { iframeid } = props;
  const [up, setup] = useState();
  useEffect(() => {
    //for resetting the button position when url changes
    setup(true);
  }, [props.url]);
  const handleTop = () => {
    debugger;
    var bool = !up;
    setup(bool);
    if (bool) {
      const element = document.getElementById(iframeid);
      element.scrollIntoView();
    } else {
      const element = document.getElementById("root");
      element.scrollIntoView();
    }
  };
  console.log("egegn", props, up);
  return (
    <div>
      <Fab
        style={{
          position: "absolute",
          left: "95vw",
          top: up
            ? document.getElementById("root").scrollHeight - 150
            : document.getElementById("root").scrollHeight - 450,
          backgroundColor: "#888a8f",
          opacity: 0.5,
        }}
        onClick={handleTop}
      >
        {up ? (
          <ArrowUpwardRoundedIcon style={{ color: "white" }} />
        ) : (
          <ArrowDownwardRoundedIcon style={{ color: "white" }} />
        )}
      </Fab>
    </div>
  );
}
FloatingButton.propTypes = {
  iframeid: PropTypes.string.isRequired,
  url: PropTypes.string,
};
export default FloatingButton;
