/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc When a route/path is not found, this page will be shown. i.e 404 - Page
 */

import React from "react";
import { Typography } from "@material-ui/core";

class NotFound extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1">404 - Not Found</Typography>
      </div>
    );
  }
}

export default NotFound;
