import { Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import Subheader from "../components/subheader";
import { Statecontext } from "../contexts";
import withAllContexts from "../HOCs/withAllContexts";
import withNavBars from "../HOCs/withNavBars";
import { useHistory } from "react-router-dom";
import FloatingButton from "../components/fab";
function Mealtype(props) {
  const state = useContext(Statecontext);
  const history = useHistory();

  useEffect(() => {
    debugger;
    if (state?.cardType === undefined) {
      history.push("/");
    }
  }, []);

  const handlescroll = () => {
    const element = document.getElementById("iframe1");
    console.log(element.scrollHeight, element.scrollTop);
    element.scrollIntoView();
    props.backDrop.setBackDrop({ open: false, message: "" });
  };
  console.log(state, props);
  return (
    <div>
      <Subheader title="Meal Order" />
      <Grid container>
        <Grid item lg={12} style={{ padding: "6px" }}>
          <iframe
            src={state?.url ?? ""}
            title={state?.name ?? ""}
            id="iframe1"
            style={{ borderWidth: 0, width: "100vw", height: "100vh" }}
            onLoad={handlescroll}
          ></iframe>
        </Grid>
        <FloatingButton iframeid={"iframe1"} />
      </Grid>
    </div>
  );
}

export default withAllContexts(withNavBars(Mealtype));
