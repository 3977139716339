/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all the routes used in the application.
 */

const Routes = {
  //Auth routes
  login: "/login",
  home: "/home",
  entertainment: "/entertainment",
  mealtype: "/mealorder",
};

export { Routes };
