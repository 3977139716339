import { AppBar, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import kpjfullheading from "../assets/Kpjfullheading.png";
const useStyles = makeStyles({
  AppBar: {
    width: "100vw",
    height: "50px",
    backgroundColor: "white",
  },
});
function Header() {
  const classes = useStyles();
  return (
    <div>
      <AppBar position="static" className={classes.AppBar}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            {" "}
            <img src={kpjfullheading} alt={"KPJ"} style={{ width: "200px" }} />
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
}

export default Header;
