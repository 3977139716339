/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Different routes and their corresponding component are defined here.
 */

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";

import { NotFound } from "./../screens";
import PrivateRoute from "./privateRouter";
import Homepage from "../screens/homepage";
import Entertainmentpage from "../screens/entertainmentpage";
import Mealtype from "../screens/mealtype";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* <PrivateRoute> */}
        <Route exact path="/" component={Homepage} />
        {/* </PrivateRoute> */}
        <Route
          exact
          path={Routes.entertainment}
          component={Entertainmentpage}
        />
        {/* <PrivateRoute> */}
        <Route exact path={Routes.mealtype} component={Mealtype} />
        {/* </PrivateRoute> */}
        {/* For unknow/non-defined path */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
