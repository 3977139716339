import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import withAllContexts from "../HOCs/withAllContexts";
import withNavBars from "../HOCs/withNavBars";
import entertaiment from "../assets/entertainment.jpg";
import meals from "../assets/meals.svg";
import healtheducation from "../assets/healtheducation.jpg";
import { Statecontext } from "../contexts";
const arraylist = [
  {
    name: "entertainment",
    cardType: "Entertainment",
    img: entertaiment,
    route: "/entertainment",
    url: "",
  },
  {
    name: "mealorder",
    cardType: "Meal Order",
    route: "/mealorder",
    img: meals,
    url: "https://ipmo.dev.ainqaplatform.in/",
  },
  {
    name: "healtheducation",
    cardType: "Health Education",
    route: "/healthedu",
    img: healtheducation,
    url: "",
  },
];
const useStyles = makeStyles({
  card: {
    minHeight: "250px",
    minWidth: "250px",
    maxHeight: "300px",
    padding: "16px",
  },
  text: {
    textAlign: "center",
  },
  cardmedia: {
    height: "180px",
  },
  griditem: {
    cursor: "pointer",
    "& :hover": {
      transform: "scale(1.1)",
      transition: "transform 1 s",
    },
  },
});
function Homepage(props) {
  const classes = useStyles();
  const [cardList, setCardList] = useState(arraylist);
  const state = useContext(Statecontext);
  const history = useHistory();

  const handleclick = async (event, cardObject) => {
    debugger;

    props.backDrop.setBackDrop({ open: true, message: "loading" });
    history.push(cardObject.route);
    state.setState(cardObject);
  };

  console.log(props);
  return (
    <div>
      {/* <Header /> */}
      <Grid container>
        {cardList.map((v, i) => {
          return (
            <Grid
              item
              xl={2}
              xs={12}
              md={6}
              sm={12}
              lg={3}
              style={{ padding: 16 }}
              onClick={(e) => handleclick(e, v)}
              key={i}
              className={classes.griditem}
            >
              <Card className={classes.card} elevation={3}>
                <CardMedia
                  component="img"
                  image={v.img}
                  alt={v.cardType}
                  className={classes.cardmedia}
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    className={classes.text}
                    color="primary"
                  >
                    {v.cardType}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withAllContexts(withNavBars(Homepage));
