/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc withNavBars HOC will give you the Navbars (Top/Bottom Navbars)
 */

import React from "react";
import { makeStyles } from "@material-ui/core";
import Header from "../components/Header";

const withNavBars = (Component) => (props) => {
  return (
    <div>
      {/* Your nav bars here */}
      <Header />
      {/* Content */}
      <div>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
