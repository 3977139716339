import { Card, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Subheader from "../components/subheader";
import withNavBars from "../HOCs/withNavBars";
import {
  Hotstarlogo,
  Netflixlogo,
  Youtubelogo,
  Primevideologo,
} from "../assets/svg/index";
import withAllContexts from "../HOCs/withAllContexts";
import FloatingButton from "../components/fab";
// import HotStarlogo from "../assets/svg/hotstar.svg";
// import Youtubelogo from "../assets/svg/youtubelogo.svg";
// import Primelogo from "../assets/svg/primelogo.svg";
// import Netflixlogo from "../assets/svg/netflixlogo.svg";

const useStyles = makeStyles({
  card: {
    display: "flex",
    width: "auto",
    height: "auto",
    maxHeight: "150px",
    // backgroundColor: "black",
  },
  cardmedia: {
    width: "80%",
    height: "auto",
  },
  griditem: {
    cursor: "pointer",
    "& :hover": {
      transform: "scale(1.1)",
      transition: "transform 0.8s",
    },
  },
});

const entertainmentdetails = [
  {
    img: <Youtubelogo />,
    name: "YouTube",
    url: "https://www.youtube.com/",
    backgroundColor: "white",
  },
  {
    img: <Primevideologo />,
    name: "amazon prime video",
    url: "https://www.primevideo.com/",
    backgroundColor: "white",
  },
  {
    img: <Netflixlogo />,
    name: "netflix",
    url: "https://www.netflix.com/",
    backgroundColor: "white",
  },
  {
    img: <Hotstarlogo />,
    name: "Hotstar",
    url: "https://www.hotstar.com/",
    backgroundColor: "black",
  },
];
function Entertainmentpage(props) {
  const classes = useStyles();
  const [entertainmentdata, setEntertainmentdata] =
    useState(entertainmentdetails);
  const [url, setUrl] = useState("");
  const [currentcard, setCurrentCard] = useState({});
  useEffect(() => {
    debugger;
    props.backDrop.setBackDrop({ open: false, message: "" });
  }, []);
  const handleclick = (event, cardobject) => {
    setUrl(cardobject.url);
    setCurrentCard(cardobject);
  };
  const handlescroll = () => {
    const element = document.getElementById("iframe");
    console.log(element.scrollHeight, element.scrollTop);
    element.scrollIntoView();
    props.backDrop.setBackDrop({ open: false, message: "" });
  };
  const handleTop = () => {
    const element = document.getElementById("root");
    element.scrollIntoView();
  };
  return (
    <div>
      <Subheader title="Entertainment" />
      <Grid container>
        {entertainmentdata.map((v, i) => {
          return (
            <Grid
              item
              xl={12}
              xs={12}
              md={12}
              sm={12}
              lg={3}
              style={{ padding: 16 }}
              onClick={(e) => handleclick(e, v)}
              key={i}
              className={classes.griditem}
            >
              {" "}
              {/* <a href={currentcard.url}> */}
              <Card
                // className={classes.card}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  backgroundColor: v.backgroundColor,
                  padding: "16px",
                }}
              >
                {/* <CardMedia
                  component="img"
                  image={v.img}
                  alt={v.name}
                  className={classes.cardmedia}
                /> */}
                {v.img}
              </Card>
              {/* </a> */}
            </Grid>
          );
        })}
      </Grid>
      {url && (
        <Grid container>
          <Grid item lg={12} style={{ padding: "16px" }}>
            <iframe
              src={url}
              title={currentcard?.name}
              id="iframe"
              style={{ borderWidth: 0, width: "100vw", height: "100vh" }}
              onLoad={handlescroll}
            />
          </Grid>
          <FloatingButton iframeid={"iframe"} url={currentcard.url} />
        </Grid>
      )}{" "}
    </div>
  );
}

export default withAllContexts(withNavBars(Entertainmentpage));
