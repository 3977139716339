import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  appbar: {
    // backgroundColor: "white",
  },
  text: {
    // color: "white",
  },
});
function Subheader(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item direction="row">
        <IconButton size="medium" onClick={() => history.push("/")}>
          <ArrowBackIcon style={{ color: "black" }} />
        </IconButton>
      </Grid>
      <Grid item direction="row">
        <Typography variant="h6" className={classes.text} color="primary">
          {props.title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Subheader;
